@font-face {
	font-family: 'Minion Variable Concept';
	src: url('./fonts/Minion Variable Concept.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

html,
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden; 
}

html,
.App {
	scroll-behavior: auto !important;
	/* overflow-x: hidden; */
	font-family: Georgia, serif;
}

html {
	overflow-y: auto;
}

.navbar {
	/* height: calc(10vh + var(--bs-navbar-padding-y)); */
	background-color: rgb(46, 49, 146) !important;
	width: 100% !important;
}

.nav-link {
	color: white !important;
	font-size: 24px !important;
}

.navbar-nav {
	background-color: rgb(46, 49, 146) !important;
}

.nav-item {
	font-weight: 500;
	margin-left: 5%;
}

.nav-link {
	padding: 0 !important;

}

.navbar-toggler {
	margin-right: 5%;
}

.navbar-toggler-icon {
	filter: invert(100%) !important;
}

#logo {
	cursor: pointer;
	margin-left: 3%;
	margin-right: 1%;
	height: 100%;
	max-height: 10vh;
	max-width: 10vh;
}

#login-small {
	margin-bottom: 0;
	visibility: hidden;
}

#login-large {
	visibility: visible;
}

.ml-auto {
	margin-left: auto;
	margin-right: 3%;
}

.slideshow {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: 1;
}

.slideshow img {
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 0.8;
	object-fit: cover;
	z-index: -1;
}

.carousel-container {
	position: relative;
}

#text-overlay {
	display: flex;
	width: 80vw;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9;
	color: #fff;
}

#text-overlay h1 {
	color: #fff;
	font-size: 2.5vw;
	text-shadow: -0.05vw -0.05vw 0 #000, 0.05vw -0.05vw 0 #000, -0.05vw 0.05vw 0 #000, 0.05vw 0.05vw 0 #000;
	font-weight: 700;
	text-align: center;
}

.overlay-button {
	text-decoration: none;
	background-color: rgb(240, 212, 148);
	color: #000;
	border: 1px solid #000 !important;
	font-size: 1.5vw;
	border: none;
	cursor: pointer;
	margin-top: 10%;
	border-radius: 50px;
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 0.5%;
	padding-bottom: 0.5%;
}

.slideshow,
.carousel-container,
.carousel-root,
.carousel {
	max-height: 85vh;
	height: 56.25vw;
}

.custom-arrow {
	font-family: Arial, Helvetica, sans-serif;
	position: absolute;
	top: 47.5%;
	transform: translateY(-75%);
	background-color: transparent;
	border: none;
	outline: none;
	color: #fff;
	cursor: pointer;
	z-index: 9;
}

.arrow-icon {
	-webkit-text-stroke-color: black;
	display: inline-block;
	font-size: 7.5vw;
	font-weight: 900;
	transform: scale(1, 2);
	text-shadow: -0.05vw -0.05vw 0 #000, 0.05vw -0.05vw 0 #000, -0.05vw 0.05vw 0 #000, 0.05vw 0.05vw 0 #000;
}

.custom-arrow-prev {
	left: 10px;
	/* Adjust the desired position */
}

.custom-arrow-next {
	right: 10px;
	/* Adjust the desired position */
}

.welcome-message {
	background-color: rgb(226, 227, 228);
}

.welcome-message h2 {
	font-size: 20px;
}

.welcome-message,
.working-with-jacqui,
.client-stories {
	padding-bottom: 3%;
}

.client-stories,
.client-stories .card {
	background-color: rgb(226, 227, 228);
}

.welcome-message h1,
.working-with-jacqui h1,
.client-stories h1 {
	font-size: 28px;
	padding-top: 2%;
	padding-bottom: 2%;
	text-align: center;
}

.client {
	margin-top: auto;
}

.client-name {
	font-size: 18px;
	font-weight: 700;
}

.welcome-message h2 {
	font-weight: 400;
	padding-left: 5%;
	padding-right: 5%;
	text-align: center;
}

.cards {
	display: flex;
	justify-content: center;
}

.card {
	border-radius: 8px;
	text-align: center;
}

.placeholder-card {
	width: 40%;
	margin: 2%;
}

.two-card  {
	width: 40%;
	margin: 0 3.75%;
}

.three-card {
	width: 27.5%;
	margin: 0 2.5%;
}

.card h2 {
	padding: 1% 0;
	font-size: 24px;
}

.card img {
	width: 100%;
	height: auto;
}

.card p {
	font-size: 18px;
	text-align: left;
	margin: 2.5% 2.5%;
}

.book-session {
	font-size: 20px !important;
	padding: 1% 1% !important;
	min-width: 25% !important;
}

.book-session,
.home .card a {
	text-decoration: none;
	color: #000;
	margin-top: auto;
	margin-bottom: 5%;
	background-color: #f0d494;
	border: none;
	font-size: 20px;
	padding: 2% 2%;
	align-self: center;
	min-width: 90%;
	border-radius: 50px;
	cursor: pointer;
}

.client-stories-cta {
	display: flex;
}

#client-stories {
	color: #000;
	text-decoration: none;
	margin-top: 2%;
	background-color: #f0d494;
	border: none;
	font-size: 20px;
	margin-left: auto;
	margin-right: auto;
	max-width: 90%;
	border-radius: 50px;
	cursor: pointer;
	padding: 1% 5%;
}

.footer {
	background-color: rgb(46, 49, 146) !important;
	padding-bottom: 2%;
	padding-top: 3%;
}

.footer img {
	max-height: 15vh;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.footer p, .footer a {
	display: block;
	margin-top: 2%;
	margin-left: 0.5%;
	margin-right: 0.5%;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	color: white;
}

#social-media {
	margin-top: 2%;
	display: flex;
	justify-content: center;
}

#social-media img {
	max-height: 6vh;
}

#copyright p {
	font-size: 16px;
	margin-top: 5%;
}

/* Coaching page */
.coaching {
	margin-top: 5%;
}

.coaching-section .card {
	justify-content: center;
	flex-direction: row;
	display: flex;
	width: 90%;
	margin-left: 5%;
	margin-bottom: 5%;
}

.coaching-information {
	width: 50%;
}

.coaching-information h1 {
	font-size: 28px;
	font-weight: 500;
	text-align: left;
}

.coaching-image {
	width: 50%;
	padding: 1%;
}

#coaching-message p {
	margin: 0;
}

.coaching-content {
	padding: 2% 2%;
}

.questions {
	margin-top: 2%;
	margin-bottom: 5%;
}

.collapsible, .collapsible-content {
	margin: 0 25% !important;
	width: 50% !important;
}

.coaching-collapsible {
	padding: 1%;
	background-color: #777;
	color: white;
	cursor: pointer;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 18px;
	margin-top: 2%;
}

.active,
.coaching-collapsible:hover {
	background-color: #555;
}

.coaching-collapsible:after {
	content: '\002B';
	color: white;
	font-weight: bold;
	float: right;
	margin-right: 2%;
}

.coaching-collapsible.active:after {
	content: "\2212";
}

.content p {
	margin: 1.5% 1.5%;
}

.book-session {
	margin: 2.5% 2.5%;
}

.content {
	max-height: 0;
	overflow: hidden;
	background-color: #f1f1f1;
}

.coaching-message p {
	margin: 0 !important;
}

.dropdown-menu {
	font-size: 18px !important;
}

.working-with-jacqui .cards .card, 
.client-stories .cards .card {
	margin-top: 0 !important;
}

/* Booking form */
.optional-text {
	color: grey;
}

.divider {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: space-between;
}

label {
	display: block;
	margin-bottom: 5px;
}

input,
textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

textarea {
	resize: vertical;
}

.booking-form {
	margin-top: 2%;
}

.full-width {
	width: 100%;
	padding-left: 34%;
	padding-right: 34%;
}

.half-width-container {
	display: flex;
	width: 100%;
}

.half-width-left {
	width: 50%;
	padding-left: 34%;
	padding-right: 1%;
}

.half-width-right {
	width: 50%;
	padding-left: 1%;
	padding-right: 34%;
}

.input-container {
	display: flex;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.phone-number-nz {
	--multiplier: 0.8; 
	--radius: calc(var(--bs-border-radius) * var(--multiplier));
	border-radius: var(--radius) 0 0 var(--radius);
	padding: 0% 2%;
	display: flex;
	align-items: center;
	background-color: #ddd;
}

#phone, #signup-phone {
	flex: 1;
	border: none !important;
}

.calendar,
.react-calendar,
.react-calendar__navigation,
.react-calendar__navigation__label,
.react-calendar__month-view__weekdays__weekday,
.react-calendar__tile {
  color: #000 !important;
}

.react-calendar__navigation__label {
	pointer-events: none !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
	display: none;
}

.calendar {
	margin-top: 3%;
	display: flex;
	justify-content: space-evenly;
}

.form-checks {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.form-check {
	padding: 1% 5%;
	padding-bottom: 0;
}

.form-check-label {
	margin: 0;
	font-size: 16px;
	margin-top: 2px;
	margin-left: 6px;
}

.grey {
	color: #767676;
	font-size: 14px;
	font-style: italic;
}

.booking-select {
	width: 100%;
	display: flex;
	justify-content: center;
}

#selectBooking {
	padding: auto;
	width: 100%;
}

.booking-form h1 {
	text-align: center;
}

#sessionSelection {
	margin-top: 3%;
}

#submitBooking {
	width: 100%;
	padding: 0 34%;
	margin-top: 2%;
	margin-bottom: 3%;
}

.booking-submit {
	width: 100%;
	background-color: #f0d494;
	border: none;
	padding: 0.5% 0;
	font-size: 24px;
	border-radius: 50px;
}

.booking-error {
	height: 2vh;
	padding: 0 34%;
	margin-bottom: 1%;
	color: #ff3333;
}

.left-booking-error {
	width: 50%;
	padding: 0;
	padding-left: 34%;
	padding-right: 1%;
}

.right-booking-error {
	width: 50%;
	padding: 0;
	padding-right: 34%;
	padding-left: 1%;
}

.ReactModal__Overlay {
	z-index: 999;
}

.modal-open {
    overflow: visible !important;
	padding: 0 !important;
}

.modal {
	padding: 0 !important;
}

.login-modal {
	/* Change this to centre it? */
	margin-top: 7.5% !important;
}

.modal-dialog {
	margin-bottom: 0 !important;
}

/* Center the modal title */
.login-modal .modal-title, .signup-modal .modal-title {
	font-size: 28px;
	text-align: center !important;
	padding-left: 5%; /* Account for the button */
}

.signup-button, .login-button {
	margin-top: 2.5%;
	width: 100%;
}

.modal-footer {
	justify-content: center !important;
}

.sign-up-link {
	padding: 0 !important;
}

.sign-up-text {
	margin: 0 !important;
}

.modal-footer {
	padding: 0 !important;
	margin: 0 !important;
	padding-top: 3% !important;
	padding-bottom: 3% !important;
}

.forgot-password-link, .sign-up-link {
	cursor: pointer;
	user-select: text;
	padding: 0 !important;
	margin: 0 !important;
}

.space {
	height: 100%;
}

.forgot-password-information {
	margin-top: 4%;
	margin-bottom: 2%;
}

.forgot-password-link, .sign-up-link {
	text-decoration: none;
}

.show-password {
	width: auto;
	margin-right: 1%;
}

.show-password-information {
	padding: 0.5%;
}

.form-control {
	border-color: #dee2e6;
	padding: 10px !important;
}

.form-label, .form-control {
	font-size: 16px !important;
}

.form-label {
	margin-bottom: 0.5% !important;
}

.flex-container {
	display: flex !important;
	padding: 0 !important;
}

.error-message {
	display: block;
	height: 1vh;
	text-align: right;
	color: red;
	padding: 0 1%;
	font-size: 16px;
}

.not-required-label {
	color: grey;
}

.successful-message {
	display: block;
	padding-top: 1%;
	height: 3vh;
	text-align: center;
	color: limegreen;
	font-weight: 500;
	font-size: 18px;
}

.unsuccessful-message {
	display: block;
	padding-top: 1%;
	height: 3vh;
	text-align: center;
	color: red;
	font-weight: 500;
	font-size: 18px;
}

.profile-logo {
	display: flex;
	justify-content: center;
	align-items: center;
}
  
.profile-logo button {
	height: 54px;
	width: 54px;
	background-color: #5075a5; /* Background color for the circular button */
	border: none; /* Remove button border */
	border-radius: 50%; /* Make it circular by setting border-radius to 50% */
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none; /* Remove underlines from the button */
	color: lightblue; /* Text color for the icon */
	cursor: pointer; /* Add a pointer cursor on hover */
}

.bi-person {
	font-size: 24px;
}

.signup-modal-body, .login-modal-body {
	padding-top: 0 !important;
}

.dropdown-menu-start {
    right: -30%;
    left: auto; 
}

.no-margin {
	margin: 0 !important;
}

.fc-col-header-cell-cushion {
	color: #000 !important;
	text-decoration: none !important;
}

.fc-direction-ltr .fc-timegrid-col-events {
	margin: 0 !important;
}

.big-height {
	height: 100vh !important;
}

.fc-timegrid-slot-lane {
	cursor: pointer;
}

.booking-calendar {
	padding: 1% !important;
}

.greyed-out-event {
	background-color: gray;
}

.fc .fc-timegrid-slot {
	height: 40px !important;
}

.fc-day-today {
	background-color: white !important;
}

.profile {
	width: 100%;
}

.profile {
	position: relative;
	overflow-x: scroll !important; /* or scroll, depending on your preference */
}

.booking-calendar {
	min-width: 1500px;
}

/* Target the "active" tile */
.react-calendar__tile--active {
	background: #006edc !important;
}
  
/* Target the "now" tile and override the background color */
.react-calendar .react-calendar__tile--now {
	background: none; /* Set it to transparent to remove the background */
}

.react-calendar__tile--now:not(.react-calendar__tile--active):hover {
	background: #e6e6e6 !important;
}

/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;
} */

.recurrence-title,
.booking-type-title,
.start-end-title,
.modal-details-title {
	font-size: 18px;
	font-weight: 500;
}

.timeManager {
	margin-top: 5%;
}

.endTimeManager {
	margin-top: 2.5%;
}

.half-width-modal-left {
	padding-left: 0 !important;
}

.half-width-modal-right {
	padding-right: 0 !important;
}

#addModalAvailability,
#addModalBooking {
	margin-bottom: 1%;
	width: 100%;
}

#addModalAvailability {
	margin-top: 5%;
}

#addModalBooking {
	margin-top: 4%;
}

.modal-add {
	width: 100%;
	background-color: #f0d494;
	border: none;
	padding: 1.5% 0;
	font-size: 18px;
	border-radius: 50px;
}

.recurrence-title {
	margin-top: 3%;
}

.start-end-title,
.modal-details-title {
	margin-top: 5%;
	margin-bottom: 1%;
}

.button-container {
	margin-top: 2.5%;
	width: 100%;
	border: medium solid grey !important;
}
  
.button-container button {
	/* border-top-color:grey !important;
	border-left-color: grey !important;
	border-right: none;
	border-bottom-color: grey !important; */
	border-left: none !important;
	border-top: none !important;
	border-bottom: none !important;
	border-right: medium solid grey;
	width: 14.2857142857%;
	height: 5vh;

	outline: none !important; /* Remove the default focus outline */
}

.button-container button:focus,
.button-container button:active {
    border-color: grey !important; /* Set the border color for focus and active states */
}

.last-button {
	border-right: none !important;
}

.hidden {
	visibility: none !important;
	display: none !important;
}

.modifying-booking {
	margin-top: 3%;
	font-weight: 700;
	font-size: 18px;
}

.modifying-booking-time,
.modifying-availability-time {
	font-weight: 400 !important;
	font-style: italic;
}

.delete-booking-button {
	background-color: rgb(236, 20, 20);
	color: #000;
	border: 1px solid #000 !important;
	width: 100%;
	font-size: 18px;
	color: white;
	border: none !important;
	margin-top: 2%;
	cursor: pointer;
	border-radius: 5px;
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 0.5%;
	padding-bottom: 0.5%;	
}

.availability-modify {
	margin-top: 4%;
}

.start-end-title {
	font-weight: 700 !important;
}

.recurring-event {
	font-size: large;
	margin-top: 2%;
}

.manage-recurrence-modify {
	font-weight: 400;
}

.add-booking-label {
	font-weight: 400;
	font-size: large;
}

.modal {
	max-width: 100vw !important;
}

.select-session-booking {
	width: 100%;
}

#bookingSessionSelection {
	margin-top: 2%;
}

.half-width-modal {
	margin-top: 3%;
}

.booking-calendar-form {
	width: 100%;
	margin-top: 2%;
	padding: 0;
}

.fc-event-title {
    white-space: pre-line !important;
    line-height: auto; /* Adjust the line height to reduce spacing */
    padding: 0; /* Remove padding */
}

.modifying-booking-title {
	font-weight: 700;
}

.modifying-booking-title,
.modifying-booking-time {
	font-size: large;
}

.no-available {
	margin-top: 0.5%;
	color: darkgray;
	font-size: large;
	font-weight: 500;
	font-style: italic;
	text-align: center;
}

.select-booking-time {
	margin-top: 0.5%;
}

.booking-list {
	display: flex;
	margin-top: 1%;
	padding: 0 20%;
	flex-direction: column;
}

.booking-card {
	border: 1px solid #ccc;
	padding: 2%;
	margin-bottom: 2%;
}

.info-item {
	margin-bottom: 0.75%;
}

.info-item strong {
	margin-right: 0.75%;
}
 
.cancel-booking {
	width: 25% !important;
}

.exercise-card {
	margin-top: 5% !important;
	border-color: grey !important;
}

.add-exercise {
	margin-top: 5% !important;
	background-color: #00a8de !important;
}

.exercise-header {
	font-size: 24px;
	display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.exercise-title {
	font-size: 22px;
}

.workout-title {
	font-size: 24px;
}

.exercise-sets, .exercise-reps {
	text-align: left;
	font-size: 18px;
}

.exercise-description {
	font-size: 16px;
	text-align: left;
}

.workout-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.edit-button {
	font-size: 20px !important;
	background: none !important;
	border: none !important;
	padding: 0 !important;
}
  
.workout-title {
	text-align: center;
	padding-left: 24px; /* Adjust for the edit button */
	flex: 1;
}

.workout-card {
	margin: 2% 1% !important; 
}

.dummy-event {
	padding-top: 0 !important;
	margin: 2% 1%;
	max-width: 100%;
	width: 400px;
	padding: 20px;
}

.workout-card,
.exercise-card,
.contactForm {
	padding-top: 0 !important;
	margin: 2% auto;
	max-width: 100%;
	width: 400px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.contactForm label {
	display: block;
	margin-bottom: 5px;
}

.contactForm input,
.contactForm select,
.contactForm textarea {
	width: 100%;
	vertical-align: top;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.exercise-delete {
	background-color: transparent !important;
	color: red !important;
	padding: 0 !important;
	margin-right: 1%;
}

.add-workout-button {
	font-size: 18px;
	background-color: #49b4d7 !important;
	min-width: 400px;
}

.workouts-title {
	margin-bottom: 0 !important;
}

.workout-row {
	justify-content: center;
	display: flex;
}

.workout-card {
	margin-top: 0% !important;
}

.add-workout-button {
	margin-bottom: 1.5%;
}

.add-workout-button,
.contactForm button {
	padding: 10px 20px;
	background-color: #007BFF;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	margin-top: 8px;
}

.profile-header {
	font-size: 32px;
	font-weight: 500;
	text-align: center;
	margin-top: 1%;
}

.user-add-workout {
	margin-top: 4% !important;
}

.cancel-button, .complete-button {
	margin-left: 1%;
}

.complete-button {
	background-color: limegreen;
}

.cancel-button {
	background-color: red;
}

.cancel-complete {
	margin-top: 4%;
	font-size: 18px;
}

.cancel-button, .complete-button {
	width: 49%;
	border: 1px solid;
	color: white;
}

.no-workouts {
	font-style: italic;
	margin: 1%;
	text-align: center;
}

.workout-error {
	color: #ff3333;
	font-weight: 600;
	height: 20px !important;
	text-align: center;
}

.workout-field-error {
	color: red;
	display: block;
	text-align: right;
	height: 21px;
	font-size: 16px;
}

.gold-icon {
	font-size: 60px;
	text-align: center;
}

.congratulations-message {
	margin-top: 1%;
	font-size: 30px;
	font-weight: 600;
	color: #00b507;
	text-align: center;
}

.congratulations-continue {
	margin-top: 5%;
	margin-bottom: 2.5%;
	display: flex;
	justify-content: center;
}

.congratulations-form-modal {
	margin-top: 20vh !important;
}

.congratulations-continue button {
	outline: none;
	color: white;
	background-color: #1CB0F6;
	border: none !important;
	font-size: 22px;
	font-weight: 500;
	box-shadow: 0 5px 0 rgb(28, 166, 220);

	/* Duolingo style button */
	display: inline-block;
	padding: 10px 20px;
	color: white;
	text-transform: uppercase;
	font-family: sans-serif;
	letter-spacing: 1.5px;
	font-weight: bold;
	cursor: pointer;
	border-radius: 17px;
}

.congratulations-continue button:active {
	box-shadow: none;
	transform: translateY(5px);
}

.testimonials {
	margin-bottom: 5%;
}

.card {
	margin: 2%;
}

.testimonials h1 {
	font-size: 24px;
	padding-top: 2%;
	text-align: center;
}

.blog h1 {
	font-size: 28px;
	padding: 1% 0;
	text-align: center;
}

.blog-content {
	display: flex;
	justify-content: center;
	margin: 5px 0;
	margin-bottom: 2.5% !important;
}

#facebook-page {
	width: 500px;
}

.contactForm {
	margin-top: 1.5% !important;
}

.contact-form h1, .frequently-asked-questions h1 {
	font-size: 28px;
	margin-top: 1% !important;
	text-align: center;
}
  

@media screen and (max-width: 1119px) {
	.workout-row {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
	}

	.dummy-event {
		width: 0 !important;
		height: 0 !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	#login-small {
		visibility: visible;
		margin-bottom: 3%;
	}

	#login-large {
		width: 0;
		height: 0;
		margin-left: 100%;
		visibility: hidden;
	}

	.nav-item {
		width: fit-content;
		margin-left: 7.5%;
		margin-top: 2%;
	}

	.nav-link {
		font-size: 20px !important;
	}

	.dropdown-item {
		font-size: 16px !important;
	}

	#placeholder {
		visibility: hidden;
		margin-left: 5%;
	}

	#logo {
		margin-left: auto;
		margin-right: auto;
	}

	.overlay-button {
		/* impossible to add a <1px border*/
		border-width: thin !important;
	}

	.cards {
		display: block;
	}

	.card {
		margin-left: 5%;
		width: 90%;
		margin-bottom: 7.5%;
	}

	#client-stories {
		margin-bottom: 2%;
	}

	#text-overlay h1 {
		font-size: 5vw;
	}

	.overlay-button {
		margin-top: 5%;
		font-size: 2.625vw;
	}

	.welcome-message h1,
	.working-with-jacqui h1,
	.client-stories h1 {
		font-size: 24px;
	}

	.welcome-message h2 {
		font-size: 16px;
	}

	.client-name {
		font-size: 16px;
	}

	.card h2 {
		font-size: 20px;
	}

	.card p {
		font-size: 16px;
	}

	.home .card a {
		font-size: 18px;
	}

	.home .card button {
		font-size: 18px;
	}

	#client-stories {
		font-size: 20px;
	}

	.footer img {
		max-height: 12.5vh;
	}

	.footer p {
		font-size: 16px;
	}

	#copyright p {
		font-size: 14px;
	}

	.dot {
		height: 6px !important;
		width: 6px !important;
	}

	/* Coaching */
	.coaching-information h1 {
		font-size: 20px;
	}

	.coaching-section .card {
		flex-direction: column-reverse;
	}

	.coaching-image {
		width: 100%;
	}

	.coaching-information {
		width: 100%;
	}

	.coaching-collapsible {
		font-size: 16px;
	}

	.book-session {
		font-size: 18px !important;
		min-width: 40% !important;
	}

	.full-width {
		padding: 0 10%;
	}

	.half-width-left {
		padding-left: 10%;
	}

	.half-width-right {
		padding-right: 10%;
	}

	.form-names {
		padding: 0 10%;
	}

	#submitBooking {
		padding: 0 10%;
	}

	.blog h1 {
		font-size: 24px;
	}

	.contact-form h1, .frequently-asked-questions h1 {
		font-size: 24px;
	}

	.collapsible, .collapsible-content { 
		margin: 0 2.5% !important;
		width: 95% !important;
	}

	#social-media img {
		max-height: 4vh;
	}
}